import React,{ useState} from 'react';
import SearchBar from '../../src/components/searchbar';

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

const Home = () => {
    const [search, setSearch] = useState("")
    const [image, ] = useState(getRandomInt(0, 4))
    return (
        <div className="App" style={{ backgroundImage: "url(" + require('../uploads/backgrounds/photo' + image + '.jpg') + ")" }}>
            <div className="container">
                <h1 className='title'>
                    UnificaBR
              </h1>
                <SearchBar  value={search} setSearch={(text)=>setSearch(text)} search={(t) => document.location.replace("/search?s="+t)} width="80%" />
                <span className="text2">Aqui você pode pesquisar todas licitações a nível federal.</span>
                <span className="text2">Exemplo de pesquisa: Cartuchos hp em São Paulo</span>
            </div>
        </div>
    );
};

export default Home;