
import React, { useState } from 'react';
import {click} from '../services/api'
function trimText(elm, sliceNumber) {

    if (elm.length > sliceNumber)
        return elm.charAt(0).toUpperCase() + elm.slice(1, sliceNumber).toLowerCase() + "...".replaceAll('\n', '').replaceAll('&nbsp', '')
    else
        return elm.charAt(0).toUpperCase() + elm.slice(1, sliceNumber).replaceAll('\n', ' ').replaceAll('&nbsp', ' ')
}
function prepareTitle(elm) {

    if (elm.todoConteudo.Titulo)
        return trimText(elm.todoConteudo.Titulo, 80)

    if (elm.todoConteudo.Modalidade && elm.todoConteudo.Numero)
        return trimText(elm.todoConteudo.Modalidade + " " + elm.todoConteudo.Numero, 80);
    if (elm.todoConteudo.Objeto)
        return trimText(elm.todoConteudo.Objeto.split('[')[0], 80);



}
function goToPage(elm){

   const urlToGo = elm.conteudo.Url ? elm.conteudo.Url : elm.todoConteudo._thread.url

   window.open(urlToGo, '_blank');
   click(elm._id);

}

export default function ResultadosPesquisa(props) {




    if(!props.topFive){
    if (props.pesquisa)
        if (props.pesquisa.data) {
            return (
                <>
                    <div className="SearchResults">



                        <span className="QuantidadePesquisa"><b>{props.pesquisa.count} Resultados para sua pesquisa de um total de {props.pesquisa.total}</b></span>
                        {props.pesquisa.data.map((elm) => {

                            return (
                                <div className="SearchItemContainer">

                                    <a  onClick={()=>goToPage(elm)}  className="SearchTitulo">{prepareTitle(elm)}</a>
                                    <span className="SearchLink">
                                        {elm.conteudo.Url ? elm.conteudo.Url.slice(0, 80) + "..." : elm.todoConteudo._thread.url.slice(0, 80) + "..."}
                                    </span>
                                    <span className="SearchDescription">
                                        <b>Abertura: </b>{elm.conteudo.Abertura}

                                    </span>
                                    {elm.todoConteudo.Fechamento &&
                                        <span className="SearchDescription">
                                            <b>Fechamento: </b>{elm.todoConteudo.Fechamento}

                                        </span>
                                    }
                                    <span className="SearchDescription">
                                        {elm.conteudo.Objeto.length > 300 ? elm.conteudo.Objeto.slice(0, 300) + '...' : elm.conteudo.Objeto}
                                        <b>{" - (" + elm.estado + ")"}</b>
                                    </span>
                                </div>
                            )
                        })}
                    </div>

                    <div style={{ display: "flex", width: '20%', justifyContent: 'center', alignItems: 'center' }}>

                    </div>
                </>
            )
        }

    return (
        <>
            <div className="SearchResults">
                <span>Sem resultados</span>

            </div>

            <div style={{ display: "flex", width: '20%', justifyContent: 'center', alignItems: 'center' }}>

            </div>

        </>
    )
    }else{







    
    return (
        <>
    {props.pesquisa.topFive && props.pesquisa.topFive.map((elm) => {

        return (
            <div className="SearchItemContainer" style={{width:300}}>

                <a onClick={() => {}} className="SearchTitulo">{prepareTitle(elm)}</a>
                <span className="SearchLink">
                  
                </span>
                <span className="SearchDescription">
                    <b>Abertura: </b>{elm.conteudo.Abertura}

                </span>
                {elm.todoConteudo.Fechamento &&
                    <span className="SearchDescription">
                        <b>Fechamento: </b>{elm.todoConteudo.Fechamento}

                    </span>
                }
                <span className="SearchDescription">
                    {elm.conteudo.Objeto.length > 300 ? elm.conteudo.Objeto.slice(0, 300) + '...' : elm.conteudo.Objeto}
                    <b>{" - (" + elm.estado + ")"}</b>
                </span>
            </div>
        )
    })}
    </>
    )
    
}




}




