import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SearchSkeleton(props) {
    return (
        <>
        <div className="SearchResults" style={{ alignItems: 'flex-start' }}>
                            <Skeleton style={{ marginBottom: 25, alignSelf: 'flex-start' }} width={180} height={15} />
                            {["", "", "", "", "", "", "", "", "", ""].map(() => {

                                return (<>


                                    <Skeleton style={{ marginBottom: 8 }} width={450} height={25} />
                                    <Skeleton style={{ marginBottom: 5 }} width={450} height={15} />
                                    <Skeleton style={{ marginBottom: 5 }} width={450} height={15} />
                                    <Skeleton style={{ marginBottom: 5 }} width={450} height={15} />
                                    <Skeleton style={{ marginBottom: 5 }} width={450} height={15} />
                                    <Skeleton style={{ marginBottom: 80 }} width={450} height={15} />
                                </>
                                )


                            })}


                        </div>


                        <div style={{ display: "flex", width: '20%', justifyContent: 'center', alignItems: 'center' }}>

                        </div>
        </>
    );
}

export default SearchSkeleton;