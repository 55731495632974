
import React  from 'react';
import { FaSearch } from "react-icons/fa";


export default function SearchBar(props) {
    
  
 
   
   

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.search(props.value);
          
        }
      }
    return (
            

        <div className="SearchBarContainer" style={{width:props.width,height:props.height,fontSize:props.fontSize}}>
            
            <input onKeyPress={_handleKeyDown} value={props.value} style={{fontSize:props.fontSize}}  onChange={(e) => props.setSearch(e.target.value)} className="SearchBar" placeholder="Digite o que procura" />
            <button onClick={() => props.setSearch("")} className="xButton">X</button>
            <button onClick={()=>{props.search(props.value)}} className="Button" style={{width:props.height,height:props.height}}><FaSearch /></button>

        </div>

    )

}




