import React, { useState, useEffect } from 'react';
import SearchBar from '../components/searchbar';
import SearchSkeleton from '../components/searchSkeleton';
import ResultadosPesquisa from '../components/resultadosPesquisa'
import Pagination from "react-js-pagination";
import { api as aPI } from '../../src/services/api';
import Modal from 'react-modal';

const customStyles = {
    overlay: {

        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};


const Search = () => {

    const [search, setSearch] = useState();
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lastSearch, setLastSearch] = useState(null)
    const [page, setPage] = useState(1)
    const [api, setApi] = useState("")

    useEffect(() => {

        const savedSearch = document.location.href.match(/s=([\w%]+[^&*])/);
        searchFunc(savedSearch ? decodeURIComponent(savedSearch[1]) : "", 1)


    }, [])


    async function searchFunc(search, pagina) {
        window.scrollTo(0, 0)
        setSearch(search)
        setLastSearch(search)
        setLoading(true)
        if (search)
            window.history.pushState(null, null, '?s=' + search)

        var result = await aPI(search ? search : "", pagina ? pagina : page)

        setApi(result);
        if (result.status == "error")
            setModal(true)
        if (result.status != "error")
            setLoading(false)


    }


    return (
        <div className="App2">
            <Modal

                isOpen={modal}
                onAfterOpen={() => { }}
                onRequestClose={() => { }}
                style={customStyles}
                contentLabel="Example Modal"
            >   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <h1 onClick={()=> document.location.replace('.')} className="title" style={{ fontSize: '35px', textShadow: 'none' }}>UnificaBR</h1>

                    <div style={{ marginBottom: 20 }}>
                        <h2>Suas pesquisas gratuitas esgotaram</h2>
                        <p>Para adquirir mais pesquisas clique no botão abaixo</p>

                    </div>
                    <button className='normalButton' onClick={() => document.location.replace("https://licitacoes.unificabrasil.com.br/cadastro.html")}>Clique aqui</button>
                </div>


            </Modal>
            <div className="header">
                <h1 onClick={()=> document.location.replace('.')} className="title" style={{ fontSize: '25px', textShadow: 'none', marginRight: '20px' }}>UnificaBR</h1>
                <div style={{ display: "flex", width: '60%', justifyContent: 'center', alignItems: 'center' }}>

                    <SearchBar setSearch={(value) => setSearch(value)} value={search} search={(text) => searchFunc(text, 0)} width="100%" height="40px" fontSize={"15px"} />
                </div>
                <div style={{ display: "flex", width: '20%', justifyContent: 'center', alignItems: 'center' }}>

                </div>
            </div>
            <div style={{ display: "flex", justifyContent: 'flex-start', marginLeft: "10%" }}>
                {loading ?

                    <SearchSkeleton />

                    :
                    <>

                        <div className="SearchResults">

                            <ResultadosPesquisa pesquisa={api} />

                        </div>

                        <div style={{ display: "flex", width: '20%', marginTop: 50, marginLeft: 50, flexDirection: 'column' }}>
                        <b><p>Mais clicados:</p></b>
                            <ResultadosPesquisa pesquisa={api} topFive={true} />
                        </div>

                    </>
                }

            </div>
            <div className="paginationContainer">


                <Pagination activePage={page} onChange={async (page) => { setPage(page); searchFunc(lastSearch, page) }} totalItemsCount={api ? (api.count ? api.count : 0) : 0} itemsCountPerPage={10} pageRangeDisplayed={5} />
            </div>
        </div>
       
	

    );
};

export default Search;