import axios from 'axios'

export async function api(search, page) {

    try {
        console.log("teste")
        const response = await axios.post('https://buscar.unificabrasil.com.br:3333/search', { client: {}, search: { s: search, page: (page ? page : 1) } });
        return response.data;

    }
    catch (err) {

        console.error(err)
    }
}
export async function click(id) {

    try {
        console.log("teste")
        const response = await axios.post('https://buscar.unificabrasil.com.br:3333/clickitem', {id});
        return response.data;

    }
    catch (err) {

        console.error(err)
    }
}