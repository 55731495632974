import React, { useState } from 'react';
//import aPI from '../src/services/api';
import Home from "../src/pages/home";
import Search from "../src/pages/search";
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";





function App(props) {




  const [, setLoading] = useState(false)
  const [, setLastSearch] = useState("")
  const [page, ] = useState(1)
  const [, setApi] = useState("")



  useState(() => {

    searchFunc()

  }, [])

  async function searchFunc(search, pagina) {
    console.log(search)
    window.scrollTo(0, 0)
    setLastSearch(search)
    setLoading(true)

    //var result = await aPI(search ? search : "", pagina ? pagina : page)
    //setApi(result);
    setLoading(false)


  }


  return (


    <Router>
      
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossOrigin="anonymous"></link>
        <Switch>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/">
            <Home />
          </Route>

        </Switch>

    </Router>

  );
}

export default App;
